
import { MapContainer, TileLayer, WMSTileLayer, useMap, GeoJSON, useMapEvent, ZoomControl } from 'react-leaflet';
import { connect, useDispatch } from "react-redux";
import L from 'leaflet';
import React from "react";
// import {TileLayerWMS} from "leaflet/src/layer/tile/TileLayer.WMS";

// import {addLayer, addSearchLayer, changeMapView} from "../actions";
import { BasemapLayer, FeatureLayer } from "react-esri-leaflet";

import { addLayer, addSearchLayer, toggleLoader, updateCurIds, updateRaw } from "../actions";
import { fetchPost, generateId, toSlug } from "../utils/helpers";
import { Urls } from '../utils/AppConfig';
import ParcelDetailPanelMobile from "./RightPanel/ParcelDetailPanelMobile";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/modal";
import LayerList from './LayerList';
import { Cross2Icon } from "@radix-ui/react-icons";
import { Tooltip } from '@nextui-org/react';
import { useState,useEffect } from 'react';
import { LayersIcon,ActivityLogIcon,HomeIcon } from '@radix-ui/react-icons';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
  } from "../shadcn/components/ui/drawer";
import { Button } from '../shadcn/components/ui/button';


const mapStateToProps = state => {
    return {
        map: state.MapReducer
    }
};

const MapLayers = (props) => {
    const map = useMap();

    const isDuplicate = () => {

    }

    let currentLayers = {};
    let currentLayerIds = Object.keys(map._layers).map(k => {
        currentLayers[map._layers[k]['options']['id']] = map._layers[k];
        return map._layers[k]['options']['id'];
    });
    let tLayers = Object.keys(props.map.layers).map(layerId => props.map.layers[layerId]);
    tLayers = tLayers.sort((a, b) => { return a.sortOrder - b.sortOrder });
    for (let i = 0; i < tLayers.length; i++) {
        if (currentLayerIds.indexOf(tLayers[i].id) === -1 && tLayers[i].active) {
            let currentLayer = tLayers[i];
            let layer = null;
            if (currentLayer.type === "WMS") {
                layer = L.tileLayer.wms(currentLayer.url, {
                    layers: currentLayer.id,
                    format: 'image/png',
                    transparent: true,
                    maxZoom: 24
                });
            }
            if (currentLayer.type === 'WMTS') {
                //http://34.29.44.168:8080/geoserver/gwc/service/wmts?layer=projectx%3Anz_contour_lines&style=&tilematrixset=3857_nz&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=3857_nz%3A18&TileCol=258215&TileRow=159796
                layer = L.tileLayer("https://projectx.nz/geoserver/gwc/service/wmts?layer=projectx%3Anz_contour_lines&style=&tilematrixset=NZ3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=NZ3857%3A{z}&TileCol={x}&TileRow={y}", {
                    maxNativeZoom: 19
                });
            }
            if (currentLayer.type === "VECTOR" || currentLayer.type === "VECTOR_GJ") {
                console.log("Adding:", currentLayer.id)
                layer = L.geoJSON(currentLayer.data);
            }
            if (currentLayer.type === "TILE") {
                layer = L.tileLayer(currentLayer.url, {
                    maxZoom: 24
                });
            }
            if (layer) {
                layer.options['id'] = currentLayer.id;
                layer.options['sortOrder'] = currentLayer.sortOrder;
                if (!(currentLayer.id in currentLayers))
                    map.addLayer(layer);
            }
        }
        if (!tLayers[i].active && currentLayerIds.indexOf(tLayers[i].id) > -1) {
            map.removeLayer(currentLayers[tLayers[i].id]);
        }
    }

    //loop through map layers & remove
    currentLayers = {};
    let layersToBeVisible = tLayers.filter(e => e.active).map(e => e.id);
    let layersInMap = Object.keys(map._layers).map(k => {
        currentLayers[map._layers[k]['options']['id']] = map._layers[k];
        return map._layers[k]['options']['id'];
    }).filter(e => Boolean(e))
    console.log(layersToBeVisible, layersInMap);
    let layersToRemove = layersInMap.filter(e => layersToBeVisible.indexOf(e) === -1).map(e => {
        let ltr = currentLayers[e];
        map.removeLayer(ltr);
    })
    console.log("Layer to be removed", layersToRemove);
    // Object.keys(currentLayers).map(key => {
    //     let layer = currentLayers[key];
    //     if ((tLayers.map(t => t.id).indexOf(key) === -1) || (!tLayers[tLayers.map(t => t.id).indexOf(key)].active)) {
    //         console.log("Removing layer: ", key);
    //         if (map.hasLayer(layer)) {
    //             map.removeLayer(layer);
    //         }
    //     }
    // });

    currentLayers = Object.keys(map._layers).map(k => {
        return map._layers[k];//['options']['id'];
    }).filter(e => e['options']['sortOrder'] !== undefined).sort((a, b) => { return a['options'].sortOrder - b['options'].sortOrder });
    currentLayers.forEach((_layer) => {
        // if (_layer['options']['sortOrder'] === undefined) return;
        // console.log(_layer['options'].sortOrder)
        if (_layer.bringToFront) {
            _layer.bringToFront();
            // console.log("YES", _layer)
        }
        // else
        //     console.log("NO", _layer)
    })


    return <></>;
}

function ClickEvent() {
    // return;
    const dispatch = useDispatch();
    const map = useMapEvent('click', (e) => {
        console.log([e.latlng.lng, e.latlng.lat]);
        let coordinates = [e.latlng.lng, e.latlng.lat];
        dispatch(toggleLoader(true));
        let token = window.localStorage.getItem('token');
        let headers = {
            "Content-Type": "application/json",
        };
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        fetch(`${Urls.ParcelByLocation}?coordinates=${coordinates.join(',')}`, { headers: headers })
            .then(r => r.json())
            .then(parcelData => {
                let urlSlug = parcelData.data['properties']['address_id'] + '-' + toSlug(parcelData.data['properties']['full_address']);
                console.log(urlSlug);

                window.history.replaceState(null, "Plot Potential - What Can I Do With My Plot?", `/map?q=${urlSlug}`);

                dispatch(toggleLoader(false));
                let searchLayerId = `address_search_${generateId(3)}`;
                dispatch(addSearchLayer(searchLayerId));
                dispatch(addLayer({
                    type: 'VECTOR_GJ',
                    id: searchLayerId,
                    active: true,
                    data: parcelData.data.geometry,
                    name: 'Address Search',
                    sortOrder: -2,
                    showLegend: false,
                    showInLayerList: false
                }))
                dispatch(updateCurIds({
                    parcelId: parcelData.data.properties.parcelId,
                    addressId: parcelData.data.properties.address_id,
                    titleNo: parcelData.data.properties.titleNo,
                    addressGeometry: parcelData.data.addressGeometry,
                    fullAddress: parcelData.data.properties['full_address']
                }));
                dispatch(updateRaw({
                    address: parcelData.data.raw.address,
                    parcel: parcelData.data.raw.parcel
                }));

                fetchPost(Urls.AddSearchHistory, {
                    parcelId: parcelData.data.properties.parcelId, address: parcelData.data.properties['full_address']
                }, token)
                    .then(response => {
                        if (response.error) {
                            return;
                        }
                        console.log(response);
                    })


                //CALCULATE PLAN

                // fetch(`${Urls.GeneratePlan}`, {
                //     body: JSON.stringify({
                //         geometry: parcelData.data.geometry
                //     }),
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                //     method: 'POST'
                // })
                //     .then(r => r.json())
                //     .then(planResult => {
                //         console.log(planResult)
                //     })

            })
            .catch(err => {
                dispatch(toggleLoader(false));
            })
    })
    return null
}



function SetViewOnClose({ status,layer }) {
    const map = useMap();

    if(status == false){
        map.getContainer().style.height = 'calc(100vh - 72px)';
        // MapLayers(layer);
    }
    // MapLayers(layer);
    // if(!opened){
    //     map.getContainer().style.Height = '100%';
    // }
    // else if(opened){
    //     map.getContainer().style.height = '50%';
    // }

    return null;
  }

const Map = (props) => {

    const [opened, setOpened] = useState(true);

    const CloseInfoPanel = () => {
        setOpened(!opened);
      }


    return (
        <div className='h-[calc(100vh-72px)] overflow-y-hidden'>

        <MapContainer
        className={` md:h-full z-10 ${opened ? "h-1/2" : "h-full"}`}
            minZoom={8}
            maxZoom={24}
            style={{  width: '100%' }}
            center={props.map.mapView.center}
            zoom={props.map.mapView.zoom}
            zoomControl={false}
            key={`${props.map.mapView.center[0]}${props.map.mapView.center[1]}`}

        >
            <ZoomControl position='topright' />
            {/*<ChangeMapZoom zoom={props.map.zoom} />*/}

            {/*<LayerHandler layers={props.map.layers} />*/}

            {/* <MapViewChangeEvent /> */}
            <ClickEvent />
            {/* <ChangeMapView
                center={props.map.mapView.center}
                zoom={props.map.mapView.zoom}
                changeMapView={props.map.changeMapView}
            /> */}
            <MapLayers map={props.map} />
<div className='md:hidden block'>
            <LayerControl />
            <SetViewOnClose
        status={opened} layer ={props}
      />
            </div>

            {
                props.map.searchLayer ? (
                    <GeoJSON data={props.map.searchLayer.data} />
                ) : ''
            }
        </MapContainer>

<div className={`md:hidden pb-5 block h-1/2 bg-[#333] ${opened ? 'block': 'hidden'}`}>

<div className="flex items-end justify-end p-3">
    <Tooltip  color='danger'
        content="Close"
         delay={0}
         closeDelay={0}
         motionProps={{
           variants: {
             exit: {
               opacity: 0,
               transition: {
                 duration: 0.1,
                 ease: "easeIn",
               }
             },
             enter: {
               opacity: 1,
               transition: {
                 duration: 0.15,
                 ease: "easeOut",
               }
             },
           },
         }}
    >
    <button onClick={CloseInfoPanel}><Cross2Icon color="#00ff99 " className="w-6 h-6" /></button>
    </Tooltip></div>

<ParcelDetailPanelMobile minimisePanel={() => { }} />
</div>

        </div>
    );
}



function LayerControl() {

    return (

        <div className='md:hidden inline-block mt-[10px] ml-[10px] ' style={{fontFamily: 'Verdana, sans-serif'}}>
        <Drawer >
        <DrawerTrigger asChild>
        {/* <button className=" leaflet-control btn btn-small text-black bg-[#00ff99] hover:text-red-500"  style={{  color: '#00FF99', backgroundColor: '#00ff99' }}  >Layers</button> */}
        <div class=" leaflet-control w-full focus:text-teal-500 hover:text-teal-500  flex flex-col items-center justify-center p-2 bg-black border-2 border-[#00ff99]" >
        <div><LayersIcon className='inline-block mb-1 h-6 w-6' color="#00ff99"/></div>
				<div class="tab tab-kategori block text-xs text-[#00ff99]">Layers</div>
			</div>
        </DrawerTrigger>
        <DrawerContent>
          <div className="mx-auto w-full max-w-sm ">
            <DrawerHeader>
              <DrawerTitle>Layers List</DrawerTitle>
            </DrawerHeader>
            <LayerList/>
            <DrawerFooter>
              <DrawerClose asChild>
              <Button variant="pp">Back To Map</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
        
        </div>



    
    )
  }

export default connect(mapStateToProps)(Map);