import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddressSearch from '../AddressSearch';
import HomeBgImg from '../../images/bg1.jpg'
import BgVideo from '../../images/BgVideo.mp4'

const style = {
    position: 'fixed',
    width: '100%',
    height: '100%',
}

const MainLandingSection2 = () => {
    return <>
        <div>
            <div style={{ position: "relative", }}>
                <video autoPlay loop muted style={{
                    height: "100vh",
                    width: "100vw",
                    objectFit: "cover",
                    pointerEvents: "none",

                }}>
                    <source src={BgVideo} type='video/mp4' />
                </video>
            </div>



            {/* <div style={{ backgroundImage: `url('${HomeBgImg}')`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}> */}
            <div style={{ textAlign: 'center', padding: 0, position: "absolute", top: 0, width: '100vw', height: '100vh' }}>
                <div elevation={0} className="content-card" style={{ textAnchor: 'middle' }}>
                    <Container>
                        <Row>
                            <Col sm={12} md={4}></Col>
                            <Col xs={12} md={12} style={{ paddingTop: 40, display: 'block', textAlign: 'center' }}>
                                <div style={{ display: 'block' }}>
                                    <br />
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={1}></Col>
                                            <Col item xs={12} md={10}><h1 className="header"><strong>Smart design and Build management platform</strong></h1></Col>
                                            <Col item xs={12} md={1}></Col>
                                        </Row>
                                    </Container>
                                    <p className="sub-header">Unlock the Potential with Plot Potential</p>
                                    <br />
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={3}></Col>
                                            <Col xs={12} md={6}>
                                                <AddressSearch />
                                            </Col>
                                            <Col xs={12} md={3}></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* </div> */}
        </div>
    </>
}

// export default MainLandingSection2;




const MainLandingSection = () => {
    return <>
        <header className="relative flex items-center justify-center h-[calc(100vh-70px)]  overflow-hidden">


            <div className='z-10' style={{ textAlign: 'center', padding: 0, position: "absolute", top: 0, width: '100vw', height: '100vh' }}>
                <div elevation={0} className="content-card" style={{ textAnchor: 'middle' }}>
                    <Container>
                        <Row>
                            <Col sm={12} md={4}></Col>
                            <Col xs={12} md={12} style={{ paddingTop: 40, display: 'block', textAlign: 'center' }}>
                                <div style={{ display: 'block' }}>
                                    <br />

<div className='pt-20'>
<h1 className="head_font sm:text-4xl md:text-5xl lg:text-6xl"><strong >Smart design and Build management platform</strong></h1>
<p className="text-2xl md:text-3xl pt-3 text-white sub_font_bold font-extrabold">Starting at $19.99. Cancel anytime.</p>
<p className="text-xl md:text-2xl pb-2 text-white sub_font font-extralight">Unlock your property’s potential with Plot Potential</p>
</div>

                                    {/* <Container>
                                        <Row>
                                            <Col xs={12} md={1}></Col>
                                            <Col item xs={12} md={10}><h1 className="head_font text-6xl"><strong >Smart design and Build management platform</strong></h1></Col>
                                            <Col item xs={12} md={1}></Col>
                                        </Row>
                                    </Container> */}

                                    <br />
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={3}></Col>
                                            <Col xs={12} md={6}>
                                                <AddressSearch />
                                            </Col>
                                            <Col xs={12} md={3}></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>



            <video
                autoPlay
                loop
                muted
                className="absolute -z-0 w-auto min-w-full min-h-full max-w-none bg-transparent object-cover"
            >
                <source
                    src={BgVideo}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </header>
    </>
}
export default MainLandingSection;
