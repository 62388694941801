
import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChevronRightIcon, ChevronLeftIcon, DownloadIcon,FileTextIcon } from "@radix-ui/react-icons"
import { fetchGet, fetchPost, toSlug } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { Urls } from "../../utils/AppConfig";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../actions";

export default function Report(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reportData, setReportData] = useState(null);
  let reportId = searchParams.get("id");
  const dispatch = useDispatch();

  let token = window.localStorage.getItem('token');
  useEffect(() => {
    fetchPost(Urls.GetReportDetails, { reportId }, token)
      .then(r => {
        console.log(r);
        if (r.error) {
          return window.alert("Server error");
        }
        setReportData(r.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  function Capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  if (!reportData) {
    return '...';
  }

  return (
    <div className="flex items-center justify-center w-full py-3 px-2">
      <div className="md:p-5 p-1 w-full xl:w-2/3 mt-5">
        <Card className="w-full  p-0 text-white border-none bg-[#111111] border-2">
          <CardHeader>
            <div className="flex items-center justify-center gap-4">
              <div className="font-medium"><ReportIcon height={60} width={60} className=" text-[#00ff99]" /></div>
              <div><CardTitle className="text-[#00ff99]"> Report Details</CardTitle></div>
            </div>
          </CardHeader>

          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <div onClick={() => {
                window.open(reportData['previewUrl'], '_blank');
              }} style={{ cursor: 'pointer', display: 'inline-block', background: '#333', padding: 20, borderRadius: 10, boxShadow: '2px 2px 2px 2px black', marginBottom: 10 }}>
                <img src={reportData['previewUrl']} style={{ maxWidth: 300, maxHeight: 300, objectFit: 'contain' }} />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center justify-center">

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Report ID</div>
                <div className="font-medium">{reportId}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Order No</div>
                <div className="font-medium">{reportData['order_ref']}</div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Parcel ID </div>
                <div className="font-medium">5123458</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Address</div>
                <div className="font-medium">{reportData['address']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Generated on </div>
                <div className="font-medium">{reportData['created_on']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Summary </div>
                <div className="font-medium"><>{Capitalize(reportData['house_type'])} with {reportData['no_of_floors']} floors</></div>
              </div>
            </div>

            <div className="md:flex items-center justify-center flex-col">


            </div>
          </CardContent>

          <CardContent className="grid gap-4  mt-4 ">
            {/* <div className="flex items-center justify-between gap-2">
                  <div className="text-[#00ff99] font-extrabold ">Report ID</div>
                  <div className="font-medium">{reportId}</div>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <div className="text-[#00ff99] font-extrabold">Parcel ID </div>
                  <div className="font-medium">5123458</div>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <div className="text-[#00ff99] font-extrabold ">Plan ID</div>
                  <div className="font-medium">658</div>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <div className="text-[#00ff99] font-extrabold ">Report Date</div>
                  <div className="font-medium">12-05-2024, 10:32 AM</div>
                </div> */}
            <div className="flex justify-center">
              {reportData.downloadUrl ? (<>
              <div className="flex flex-col sm:flex-row items-center justify-center">
              <div className="p-2">
              <Button variant="pp" className="text-black font-bold" onClick={() => {
                let token = window.localStorage.getItem('token');
                window.open(reportData.downloadUrl, '_blank').focus();
              }}>
                <DownloadIcon className="h-4 w-4" /><span className="px-3">Download Report</span>
              </Button>
              </div>
              <div className="p-2">
              <Button  variant="warning"
               onClick={async () => {
                dispatch(toggleLoader(true));
                let manualReport = await fetchGet(`${Urls.RequestManualReport}?reportId=${reportId}&token=${token}`, token);
                dispatch(toggleLoader(false));
                if(manualReport.error){
                    return window.alert("Server error. Please, try again later.");
                }
                return window.alert(manualReport.message);
            }}
        >      
         <FileTextIcon className="h-4 w-4" /><span className="px-3">Request Manual Report</span>
        </Button>  
        </div>
        </div>
            </>) : ''}
            </div>
            <Separator className="my-2" />
          </CardContent>
          <CardFooter className="flex w-full gap-5 pt-1 px-4 md:px-6 justify-center">
            <div>
              <Button className="font-bold" variant="destructive" onClick={() => {
                navigate("/home")
              }}>
                Back To Home
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

function ReportIcon({ height, width }) {
  return (
    <svg height={height} width={width} version="1.1" id="Layer_1" viewBox="0 0 512.001 512.001" fill="#000000">
      <circle fill="#71B84F" cx="256.001" cy="255.977" r="255.977"></circle>
      <path fill="#63A147" d="M129.244,418.535l90.958,90.958c11.733,1.655,23.667,2.508,35.851,2.508 c135.133,0,245.796-104.696,255.323-237.422L382.863,146.064L129.244,418.535z"></path>
      <path fill="#FFD630" d="M129.244,93.465h200.117l53.451,52.599v272.472H129.193V93.465H129.244z"></path>
      <path fill="#E7A20B" d="M355.736,119.79l27.127,26.224v41.618l-53.451-41.468l26.374-26.374H355.736z"></path>
      <path fill="#B2B3B3" d="M329.361,93.465l53.451,52.599l-53.451,0.1V93.465z"></path>
      <path fill="#F5BE18" d="M301.883,242.336c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055H301.883z M299.726,152.382 c16.346,11.132,27.127,29.934,27.127,51.246c0,13.338-4.212,25.673-11.382,35.801l-50.543-35.801L299.726,152.382z M258.259,133.177 c12.887,0,24.87,3.961,34.798,10.731l-34.798,51.194l-5.265-61.725c1.755-0.15,3.51-0.251,5.265-0.251V133.177z"></path>
      <path fill="#E84F4F" d="M299.325,237.522c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055L299.325,237.522L299.325,237.522z M255.702,128.364 c12.887,0,24.92,3.961,34.798,10.731l-34.798,51.246l-5.265-61.725C252.192,128.464,253.947,128.364,255.702,128.364z"></path>
      <path fill="#12B589" d="M297.17,147.618c16.346,11.132,27.127,29.934,27.127,51.194c0,13.338-4.212,25.673-11.382,35.801 l-50.543-35.801L297.17,147.618z"></path>
      <path fill="#FFFFFF" d="M180.84,355.507h30.286v34.598H180.84V355.507z M162.488,397.375h187.08v9.226h-187.08V397.375z M300.93,272.773h30.286v117.382H300.93V272.773z M260.917,346.631h30.286v43.523h-30.286V346.631z M220.904,325.271h30.286v64.934 h-30.286V325.271z"></path>
    </svg>
  )
}




