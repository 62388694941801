import * as React from 'react';
import { connect } from 'react-redux';
import MainLandingSection from '../components/Landing/MainSection';
import AppHeader from '../components/AppHeader.js';
import ServiceSection from '../components/Landing/ServiceSection';
import AboutSection from '../components/Landing/AboutSection';
import ContactSection from '../components/Landing/ContactSection';
import PaneSection from '../components/Landing/PaneSection';
import FooterSection from '../components/Landing/FooterSection';
import {CRMSection,AboutHomeSection,ReportSection,TreeSection,PartnerHomeSection,ServiceHomeSection,FeatureSection} from '../components/Landing/HomeSection';
import HeaderSection from '../components/Landing/HeaderSection';
import SubscriptionPlan from '../components/Profile/subscription/SubscriptionPlan';
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer
    }
}


function LandingPage(props) {


    return <>
    <Cursor/>
    <ScrollToAnchor />

        {/* <AppHeader /> */}
        <HeaderSection />
        <div style={{ height: 70, width: '100%', background: 'black' }}></div>
        <MainLandingSection />
        <FeatureSection/>
        <ReportSection/>
        <CRMSection/>
        <AboutHomeSection/>
        {/* <TreeSection/> */}
        <ServiceHomeSection />

        {/* <PartnerHomeSection/> */}
        <SubscriptionPlan />


        {/* <PaneSection /> */}
        {/* <ServiceSection /> */}


        {/* <AboutSection /> */}
        <FooterSection />
        
        
        
        {/* <ContactSection /> */}


        {/* <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{ width: '100%' }}>
                <Toolbar style={{ backgroundColor: 'black' }} />
                <LandingPageContent scrollTo={scrollTo} />
            </Box>
        </Box> */}
    </>;
}
export default connect(mapStateToProps)(LandingPage);