

import React, { useState,useEffect } from 'react';
import {Accordion, AccordionItem} from "@nextui-org/react";

const ReportSection = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const services =
    [
        {
            id:"1",
            title:"Graphical representations of dwellings",
            content:"This includes detailed graphical representations of potential dwelling types and scenarios, including single-storey dwellings, townhouses, duplexes, and zero-lot boundary designs. These representations showcase the architectural design, floor plans, elevations, and key features of the dwellings, providing a visual understanding of their layout and aesthetics."
        },
        {
            id:"2",
            title:"Driveway, outlook space and outdoor living space",
            content:"These architectural representations highlight the design and layout of driveways, outlook spaces, and outdoor living areas. They illustrate how these spaces can be integrated into the overall design of the property, including their size, location, access points, landscaping features, and any amenities such as patios, decks, or gardens."
        },
        {
            id:"3",
            title:"Potential infringements",
            content:"A list is provided outlining potential infringements that must be considered. These infringements could include height restrictions, zoning regulations, or any other legal or regulatory requirements that may impact the proposed development. Identifying these potential infringements from the start allows for early resolution and compliance with relevant laws and guidelines."
        },
        {
            id:"4",
            title:"Zone perimeters",
            content:"This aspect identifies and illustrates boundaries and perimeters that may affect the proposed development. It includes factors such as setbacks, easements, utility corridors, or any other physical or legal limitations that need to be taken into account during the design and construction process."
        },
        {
            id:"5",
            title:"Proposed retaining walls, cut, and fills (feature coming soon)",
            content:"The architectural representations showcase the proposed retaining walls, as well as the cut and fill areas on the site. This includes graphical illustrations of the location, height, and design of retaining walls, as well as the areas of excavation (cuts) and areas where soil is added (fills). These representations help visualize how the site will be modified to accommodate the development and address any level or slope challenges."
        },
        {
            id:"6",
            title:"Development engineering possibilities",
            content:"This aspect covers various engineering considerations related to the proposed development, including stormwater and wastewater information for the proposed site and capacity checks of reticulation networks. It also identifies necessary earthworks to achieve FFL compliance, flood risks, soil conditions, drainage feasibility, and driveway requirements."
        },
        {
            id:"7",
            title:"Costing",
            content:"As well as identifying development possibilities, our system estimates the project's total cost and expected ROI. This report breaks the cost down into the design stage, consent stage and build stage categories, to help you assess the project's financial feasibility and cashflow arrangements."
        },
        {
            id:"8",
            title:"Hot Plots",
            content:"Through customer market analysis, we provide information about development sites based on your location and development type preferences. This feature saves you the manual search of finding a site that ticks the boxes you need, instead tailoring results to your own development capacity and plans."
        }
    ];

    return <>
      <section  >
            <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                <p className="head_font2 md:text-5xl text-4xl font-bold text-[#00ff99] pt-5 ">
                PLOT POTENTIAL REPORTS
                    </p>
                    <div className='content-text justify-center items-center text-center py-4'>
            Our reports consolidate data, regulations and site-specific requirements and restraints to show you the potential of your plot. 
            </div>



            <div className=' flex items-center justify-center'>
            <div className='md:w-3/4 w-11/12'>


                    <Accordion variant='splitted'
            itemClasses={
                {
                    base:"!bg-[#333333] text-white hover:bg-gradient-to-r from-[#07b16d88] to-[#71aec088]  hover:text-white",
                    title:"text-white"
                }
            }
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease",
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: "ease",
                duration: 0.25,
              },
              opacity: {
                easings: "ease",
                duration: 0.3,
              },
            },
          },
        },
      }}
    >
            {services.map((item,idx)=>(
                <AccordionItem key={item.id} aria-label={item.id} title={item.title} className='text-left text-xl' >
                {item.content}
                </AccordionItem>
            ))}
            </Accordion>
            </div>
            </div>
                    </div>
                    </div>
                    </section>
</>
}


export default ReportSection;