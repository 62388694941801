// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayerList from "../LayerList";
import { Theme } from "../../utils/AppConfig";
import ParcelDetailPanel from "../RightPanel/ParcelDetailPanel";
import { TriangleRightIcon,TriangleLeftIcon } from "@radix-ui/react-icons";

const SidePanel = (props) => {

    const [opened, setOpened] = useState(false);
    const [activeTab, setActiveTab] = useState('layers')
    const togglePanel = () => {
        setOpened(!opened);
    };
    const panelContainerStyle = {
        width: opened ? 'calc(min(100%, 400px) - 25px)' : '0px', // Adjust the panel width as needed
        height: 'calc(100vh - 72px)',
        maxHeight: 'calc(100vh - 72px)',
        backgroundColor: '#333',
        borderRadius: 0,
        position: 'fixed',
        top: '72px',
        left: 0,
        zIndex: 1001,
        transition: 'width 0.3s ease',
    };

    const buttonStyle = {
        position: 'absolute',
        top: '-4px',
        width: 25,
        borderRadius: '0px 2px 2px 0px',
        left: opened ? 'calc(min(100%, 400px))' : '0px', // Adjust the right position as needed
        zIndex: 1002, // Ensure the button appears on top of the panel
        transition: 'left 0.3s ease',
    };
    const tabHeaderStyle = {
        backgroundColor: Theme.Background, padding: 4, textAlign: 'center',
        color: '#fff',
        cursor: 'pointer'
    };



    return (
        <div style={panelContainerStyle} className="hidden md:block">
            <button
                className="btn btn-small p-3"
                onClick={togglePanel}
                style={buttonStyle}
            >
                
                {opened ? <TriangleLeftIcon className="h-5 w-5 "/>:<TriangleRightIcon className=" h-5 w-5 "/> }
                {/* <FontAwesomeIcon icon={`fa-solid ${opened ? "fa-caret-left" : "fa-caret-right"}`} /> */}
            </button>
            {opened && <div>
                <div>
                    <Row>
                        <Col xs={6} style={{
                            ...tabHeaderStyle,
                            backgroundColor: activeTab === 'layers' ? '#333' : Theme.Background,
                            border: '1px solid ' + (activeTab === 'layers' ? Theme.PrimaryLogo : 'transparent'),
                            borderBottom: (activeTab === 'layers' ? 'none' : 'inherit')
                        }} onClick={() => {
                            setActiveTab('layers');
                        }}>
                            <span>Layers</span>
                        </Col>
                        <Col xs={6} style={{
                            ...tabHeaderStyle,
                            backgroundColor: activeTab === 'details' ? '#333' : Theme.Background,
                            border: '1px solid ' + (activeTab === 'details' ? Theme.PrimaryLogo : 'transparent'),
                            borderBottom: (activeTab === 'details' ? 'none' : 'inherit')
                        }} onClick={() => {
                            setActiveTab('details');
                        }}>
                            <span>Plot Details</span>
                        </Col>
                    </Row>
                </div>
                {
                    activeTab === 'layers' ? (<LayerList />) : ''
                }
                {
                    activeTab === 'details' ? (
                        <ParcelDetailPanel minimisePanel={() => { }} />
                    ) : ''
                }
            </div>}
        </div>
    );
}

export default SidePanel;