
import * as React from "react";
import { connect, useDispatch } from 'react-redux';
import OverviewDetails from './OverviewDetails';
import SalesHistory from "./SalesHistory";
import TitleValuation from "./TitleValuation";
import { removeSearchLayer, setParcelOverview, toggleLoader, toggleManualOrderDialog, updateCurIds, updateRaw } from "../../actions";
import { Constants, Urls } from "../../utils/AppConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RightComponent from './RightComponent'

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const ParcelDetailPanelMobile = (props) => {

    const dispatch = useDispatch();

    const [salesHistory, setSalesHistory] = React.useState([]);
    const [rateAssessment, setRateAssessment] = React.useState(null);
    const [parcelOverview, setLocalParcelOverview] = React.useState({});
    // const [demogData, setDemogData] = React.useState(null);
    // const [loadStatus, setLoadStatus] = React.useState(0);

    const [isLoading, setLoading] = React.useState(false);

    React.useEffect(() => {
        console.log(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry)
        if (!isLoading && Boolean(props.data.currentParcelId) && Boolean(props.data.currentAddressId) && Boolean(props.data.currentTitleNo) && Boolean(props.data.addressGeometry)) {
            setLoading(true);
        }

    }, [props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry]);

    React.useEffect(() => {
        if (isLoading) {
            loadAllData(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry.coordinates)
            // loadParcelOverview(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo);
            // fetchSalesHistory(props.data.currentTitleNo);
            // loadTitleValuation(props.data.currentTitleNo);
            // loadDemographyData(props.data.addressGeometry.coordinates);
        }
    }, [isLoading])


    const loadAllData = async (parcelId, addressId, titleNo, coordinates) => {

        const fetchPromise = (url) => {
            let token = window.localStorage.getItem('token');
            let headers = {

            };
            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            return new Promise((resolve, reject) => {
                fetch(url, { headers })
                    .then(r => r.json())
                    .then(r => {
                        resolve(r);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        }
        const fetchPromiseNoToken = (url) => {
            let token = window.localStorage.getItem('token');


            return new Promise((resolve, reject) => {
                fetch(url)
                    .then(r => r.json())
                    .then(r => {
                        resolve(r);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        }

        titleNo = titleNo.split(',').map(e => e.trim());
        titleNo = titleNo[0];
        let shurl = `${Urls.SalesHistory}?titleNo=${titleNo}`;
        // let tvUrl = `${Urls.TitleValuation}?titleNo=${titleNo}`;
        let tvUrl = `${Constants.NZ_GEOMAPS_ESRI_SERVER}/3/query?f=json&where=CT='${titleNo}'&returnGeometry=false&spatialRel=&geometry=&geometryType=esriGeometryPolygon&inSR=4326&outFields=*&outSR=4326`;
        let poUrl = `${Urls.ParcelOverview}?parcelId=${parcelId}&addressId=${addressId}&titleNo=${titleNo}`;
        // let deUrl = `${Urls.Demography}?coordinates=${coordinates.join(",")}`;
        let promises = [
            fetchPromise(shurl),
            fetchPromiseNoToken(tvUrl),
            fetchPromise(poUrl),
            // fetchPromise(deUrl)
        ];

        setSalesHistory([]);
        // setDemogData(null);
        setLocalParcelOverview({});
        dispatch(setParcelOverview(null));
        setRateAssessment(null);

        dispatch(toggleLoader(true));
        let results = await Promise.all(promises);
        console.log(results);
        setSalesHistory(results[0].data);
        if (results[1] && results[1].features && results[1].features.length > 0) {
            setRateAssessment(results[1].features[0]);
        }

        let repData = {

        }

        try {
            dispatch(updateRaw({
                ...props.data.raw,
                gisDetails: results[2].data.raw.gisDetails,
                pc78: results[2].data.raw.pc78,
                title: results[2].data.raw.title,
                salesHistory: results[0].data
            }))
            delete results[2]['data']['raw']
            setLocalParcelOverview(results[2]);
            dispatch(setParcelOverview(results[2].data));
            // setDemogData(results[3].data);
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
        dispatch(toggleLoader(false));



    }

    const exportPdf = async () => {
        const canvas2Blob = (c) => {
            return new Promise((resolve, reject) => {
                c.toBlob((b) => {
                    return resolve(b);
                })
            })
        }

        let c1 = document.getElementById('c1'),
            c2 = document.getElementById('c2'),
            c3 = document.getElementById('c3'),
            c4 = document.getElementById('c4'),
            c5 = document.getElementById('c5');

        c1 = await canvas2Blob(c1);
        c2 = await canvas2Blob(c2);
        c3 = await canvas2Blob(c3);
        c4 = await canvas2Blob(c4);
        c5 = await canvas2Blob(c5);

        const formData = new FormData();

        formData.append('c1', c1, 'c1.png');
        formData.append('c2', c2, 'c2.png');
        formData.append('c3', c3, 'c3.png');
        formData.append('c4', c4, 'c4.png');
        formData.append('c5', c5, 'c5.png');
        formData.append('parcelId', props.data.currentParcelId);
        formData.append('rateAssessment', JSON.stringify(rateAssessment));
        formData.append('pc78', JSON.stringify(props.data.raw.pc78));
        formData.append('gisDetails', JSON.stringify(props.data.raw.gisDetails));
        formData.append('title', JSON.stringify(props.data.raw.title));
        formData.append('parcel', JSON.stringify(props.data.raw.parcel));
        formData.append('address', JSON.stringify(props.data.raw.address));
        formData.append('salesHistory', JSON.stringify(props.data.raw.salesHistory));

        dispatch(toggleLoader(true));
        // fetch(`${Urls.PdfReport}`, {
        fetch(`https://api.projectx.nz/data/getPdfReport`, {
            // fetch(`http://localhost:8081/data/getPdfReport`, {
            method: 'POST',
            body: formData
        }).then(r => r.blob())
            .then(r => {
                var fileURL = window.URL.createObjectURL(r);
                let tab = window.open();
                tab.location.href = fileURL;
                dispatch(toggleLoader(false));
            })
            .catch(e => {
                console.log(e);
                dispatch(toggleLoader(false));
            })
    }

    console.log(parcelOverview);

    return <div id="parcel_details_panel" className="overflow-y-auto h-full" style={{  padding: 0, width: '100%', background: 'transparent',fontFamily: 'Verdana, sans-serif' }} >
        <div style={{}}>
            <div style={{ position: 'relative', width: '100%', padding: 8 }}>
                <h6 className="header-3-small">
                    {props.data.fullAddress ? props.data.fullAddress : ''}
                    <div style={{ float: 'right' }}>
                        {/* <IconButton aria-label="export">
                            <ExitToAppIcon />
                        </IconButton> */}
                        {/* <IconButton aria-label="delete" onClick={() => {
                            resetCurrentSearch()
                        }}>
                            <CancelIcon />
                        </IconButton> */}
                    </div>
                </h6>
            </div>

            <div >

                <OverviewDetails data={parcelOverview} />

                <TitleValuation data={rateAssessment} />

                <SalesHistory data={salesHistory} />
                <RightComponent />

                {/* <Demography data={demogData} /> */}
            </div>
            {
                Boolean(props.data.user) ? (
                    <div style={{ position: 'relative', width: '100%', padding: 8, borderRadius: 3, background: 'transparent', bottom: 40 }}>
                        <Row >
                            <Col xs={12} md={6} item>
                                <button className="btn btn-small" style={{ width: '100%', color: '#00FF99', backgroundColor: '#333' }} onClick={() => { exportPdf() }}>Free Report</button>
                            </Col>
                            <Col xs={12} md={6} item>
                                <button className="btn btn-small" style={{ width: '100%', color: '#00FF99', backgroundColor: '#333', borderColor: '#00FF99' }} onClick={() => {
                                    // props.minimisePanel();
                                    // resetCurrentSearch();
                                    dispatch(toggleManualOrderDialog(true, { address: props.data.fullAddress, addressId: props.data.currentAddressId }));
                                    //TODO: Testing
                                    // dispatch(toggleLoader(true));
                                    // fetch(`${Urls.TestArchReport}?addressId=${props.data.currentAddressId}`)
                                    //     .then(r => r.blob())
                                    //     .then(r => {
                                    //         var fileURL = window.URL.createObjectURL(r);
                                    //         let tab = window.open();
                                    //         tab.location.href = fileURL;
                                    //         dispatch(toggleLoader(false));
                                    //     })
                                    //     .catch(e => {
                                    //         console.log(e);
                                    //         window.alert("Error")
                                    //         dispatch(toggleLoader(false));
                                    //     })
                                }}>Auto Report</button>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={12} item>

                            </Col>
                        </Row>
                    </div>
                ) : ''
            }
        </div>
    </div>
}



export default connect(mapStateToProps)(ParcelDetailPanelMobile);