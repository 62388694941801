import * as React from 'react';
import EmpLogoPng from '../images/emp_logo.png'
import { Theme } from '../utils/AppConfig';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { connect, useDispatch } from 'react-redux';
import { setUser, toggleManualOrderDialog } from '../actions';
import AddressSearch from './AddressSearch';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const HomeHeader = (props) => {
    const dispatch = useDispatch();
    {/* <div style={{ maxWidth: '95%', display: 'flex', flexWrap: 'inherit', alignItems: 'center', justifyContent: 'space-between' }}></div> */ }
    return <>
        <Navbar data-bs-theme="dark" expand="lg" style={{ backgroundColor: '#000000EE', padding: 0,fontFamily: 'Verdana, sans-serif' }} className='min-h-[72px] z-10'>
            <Container style={{ maxWidth: '95%' }}>
                <Navbar.Brand style={{ color: Theme.PrimaryLogo, margin: 'initial' }}>
                    <Link to={"/"}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        PlotPotential
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    {/* {
                        Boolean(props.data.user) ? (<Nav className='me-auto'>
                            <div style={{ margin: 16 }}><AddressSearch isInput={false} height={40} /></div>
                        </Nav>) : ''
                    } */}
                    <Nav className='me-auto'>
                        <div style={{ margin: 16 }}><AddressSearch isInput={false} height={40} /></div>
                    </Nav>

                    <Nav className="ms-auto">
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link style={{ color: Theme.PrimaryLogo }} to={'/home'}>Home</Link>
                            </Nav.Link>) : ''
                        }
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link style={{ color: Theme.PrimaryLogo }} to={'/map'}>Map</Link>
                            </Nav.Link>) : ''
                        }
                    </Nav>
                    {
                        !Boolean(props.data.user) ? (
                            <Nav>
                                <Nav.Link><Link style={{ color: Theme.PrimaryLogo }} to={'/signIn'}>Sign In</Link></Nav.Link>
                                {/* <Nav.Link><Link style={{ color: Theme.PrimaryLogo }} to={'/signUp'}>Sign Up</Link></Nav.Link> */}
                            </Nav>
                        ) : (
                            <Nav>
                                {/* <Nav.Link><Link style={{ color: Theme.PrimaryLogo }} to={'/newOrder'}>Generate Report</Link></Nav.Link> */}
                                <Nav.Link href="#"><Button onClick={() => {
                                    dispatch(setUser(null));
                                    window.localStorage.removeItem('token');
                                }} variant="outline-success" >Log Out</Button></Nav.Link>
                            </Nav>
                        )
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}

export default connect(mapStateToProps)(HomeHeader);