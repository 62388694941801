import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import Report from '../components/Report/Report'

const ReportPage = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    let reportId = searchParams.get("id");

    return <>
      <Report props={reportId} />
    </>
}

export default ReportPage;